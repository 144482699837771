import { DependencyList, useCallback, useEffect, useState } from 'react';
import { Observable } from 'rxjs/Observable';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import 'rxjs/add/operator/finally';

import { useLoader } from 'app/hooks/use-loader.hook';
import { cancelRequest } from 'app/util/util';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';

interface UseAjaxRequestHook {
  <T>(factory: (...args: any[]) => Observable<T>, callback: (value: T) => any, deps: DependencyList, errorHandler?: (e: Error | APIErrorResponse | AjaxError) => any, loaderName?: string): (...args: any[]) => void;
}

// returns a function to make a request on demand.  Any arguments passed to that function
// will be passed directly to the factory when called.
export const useAjaxRequest: UseAjaxRequestHook = (factory, onSuccess, deps, errorHandler, loaderName) => {
  const loader = useLoader(loaderName);
  const [request, setRequest] = useState(null);

  useEffect(() => {
    // cancel pending request on unmount.
    return () => cancelRequest(request);
  }, []);

  return useCallback((...args: any[]) => {
    loader.show();
    cancelRequest(request);

    const innerRequest =
      factory(...args, ...deps)
        .finally(loader.hide)
        .subscribe(onSuccess, errorHandler);

    setRequest(innerRequest);
  }, [...deps, factory, onSuccess, errorHandler, loader, request]);

};
